import UFunction from "./UFunction.mts";
import UObject from "./UObject.mts";

namespace UProxy {
  export const get = <Key extends UObject.Key, R>(
    keys: readonly Key[],
    receive: (key: Key) => R,
  ) =>
    new Proxy({} as Record<Key, R>, {
      get(target, p) {
        if (keys.includes(p as Key))
          return (target[p as Key] ??= receive(p as Key));
      },
    });

  export const lazyFns = <T extends UObject.Any | UFunction.Any>(
    targetAccessor: () => T,
  ) =>
    new Proxy(targetAccessor, {
      get(_, p): any {
        // @ts-expect-error
        return lazyFns(() => targetAccessor()[p]);
      },
      apply(target, _, args) {
        // @ts-expect-error
        return target()(...args);
      },
    }) as T;

  export function deepShim<Obj extends UObject.Any>(
    obj: Obj,
    shim: (original: UFunction.Any, path: UObject.Key[]) => UFunction.Any,
  ): Obj {
    return new Proxy(typeof obj === "function" ? () => {} : {}, {
      get(_, p): any {
        const field = (obj as any)[p];

        return (
          field &&
          deepShim(field, (original, path) => shim(original, [p, ...path]))
        );
      },
      apply(_, __, args) {
        return shim(obj as UFunction.Any, [])(...args);
      },
      has(_, p) {
        return p in obj;
      },
    }) as any;
  }

  export function pick<Obj extends UObject.Any, Properties extends keyof Obj>(
    target: Obj,
    properties: Properties[],
  ): Pick<Obj, Properties> {
    return new Proxy(target, {
      get(_, p: any) {
        if (!properties.includes(p))
          throw new Error(`Property ${p} inaccessible.`);
        return (target as any)[p];
      },
    });
  }

  export function merge<
    Target extends UObject.Any | undefined,
    Source extends UObject.Any | undefined,
  >(target: Target, source: Source, _this?: any): Target & Source {
    if (!target) return source as any;
    if (!source) return target as any;

    const isFn = UFunction.is.some(target, source);

    return new Proxy(isFn ? () => {} : {}, {
      get(_, p) {
        const targetField = (target as any)[p];
        const sourceField = (source as any)[p];

        if (
          targetField &&
          (typeof targetField === "object" || typeof targetField === "function")
        ) {
          return merge(
            targetField,
            sourceField,
            typeof sourceField === "function" ? source : target,
          );
        }
        return p in source ? sourceField : targetField;
      },
      apply(_, __, args) {
        if (typeof source === "function") return source.apply(_this, args);
        return (target as any).apply(_this, args);
      },
      construct(_, args) {
        try {
          return new (target as any)(...args);
        } catch (e) {
          return new (source as any)(...args);
        }
      },
      has(_, p) {
        return p in source || p in target;
      },
      ownKeys() {
        // TODO: Use UArray.dedupe after namespace transformer, will allow for
        // circular dependencies between namespaces
        return [...new Set([...Object.keys(target), ...Object.keys(source)])];
      },
      getOwnPropertyDescriptor(_, p) {
        return Reflect.getOwnPropertyDescriptor(
          p in source ? source : target,
          p,
        );
      },
    }) as any;
  }
}

export default UProxy;
