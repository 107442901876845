import UWeakMap from "./UWeakMap.mts";

namespace UMap {
  export type Any = Map<any, any>;
  export type Key<M extends Any> = M extends Map<infer Key, any> ? Key : never;
  export type Value<M extends Any> =
    M extends Map<any, infer Value> ? Value : never;

  export const getOr = UWeakMap.getOr;

  export function equal(map1: Any, map2: Any) {
    if (map1.size !== map2.size) return false;
    for (const [key, value] of map1) {
      if (value !== map2.get(key)) return false;
    }
    return true;
  }
}

export default UMap;
