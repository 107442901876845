import Range from "./Range.mts";
import UNumber from "./UNumber.mts";

namespace UArrayLike {
  export function join<A extends ArrayLike<any>>(
    arr: A,
    joinFn: (
      index: Range<0, UNumber.Subtract<A["length"], 1>>[number],
      array: A,
    ) => string,
  ) {
    if (arr.length === 0) return "";
    let res = `${arr[0]}`;
    for (let i = 0; i < arr.length - 1; i++) {
      res += joinFn(i as any, arr) + arr[i + 1];
    }
    return res;
  }
}

export default UArrayLike;
