import UArray from "./UArray.mts";
import UNumber from "./UNumber.mts";

namespace Compare {
  export type Order = keyof typeof order;
  export type Comparable = string | number | undefined;
  export type Selector<T> =
    | UArray.Maybe<
        (subject: T) => UArray.Maybe<Comparable>
        // May be useful in the future, must add impelmention if so
        // | [Comparable, Order][]
      >
    | [(subject: T) => UArray.Maybe<Comparable>, Order][];

  export const order = {
    asc: (a: Comparable, b: Comparable) => {
      if (a == null) return b == null ? 0 : -1;
      if (b == null) return 1;
      return a > b ? 1 : a < b ? -1 : 0;
    },
    desc: (a: Comparable, b: Comparable) => -order.asc(a, b) as UNumber.Sign,
  };

  export const by = <T,>(a: T, b: T, selector: Selector<T>): UNumber.Sign => {
    for (const layer of [selector].flat()) {
      const [selector, order] =
        layer instanceof Array ? layer : ([layer, "asc"] as const);

      const resA = [selector(a)].flat();
      const resB = [selector(b)].flat();

      for (let i = 0; i < resA.length; ++i) {
        const res = Compare.order[order as Order](resA[i]!, resB[i]!);
        if (res) return res;
      }
    }
    return 0;
  };

  export type Comparator<T> = (a: T, b: T) => UNumber.Sign;
  export function comparator<T>(selector: Selector<T>): Comparator<T>;
  export function comparator<T>(comparator: Comparator<T>): Comparator<T>;
  export function comparator<T>(
    selectorOrComparator: Selector<T> | Comparator<T>,
  ): Comparator<T>;
  export function comparator<T>(
    selectorOrComparator: Selector<T> | Comparator<T>,
  ): Comparator<T> {
    if (
      typeof selectorOrComparator === "function" &&
      selectorOrComparator.length === 2
    )
      return selectorOrComparator as Compare.Comparator<T>;

    return (a, b) => by(a, b, selectorOrComparator as Compare.Selector<T>);
  }
}

export default Compare;
