import { Tuple } from "record-tuple";

import UTuple from "./UTuple.mts";

namespace UFunction {
  export type Maybe<Args extends any[], T> = ((...args: Args) => T) | T;
  export type Unwrap<T extends Maybe<any, any>> = T extends Any
    ? ReturnType<T>
    : T;

  export type Any = (...args: any) => any;
  export namespace Any {
    export type Void = (...args: any) => void;
  }

  export type OptionalArgs<Fn extends Any> = (
    ...args: UTuple.Partial<Parameters<Fn>>
  ) => ReturnType<Fn>;

  export function is(maybeFn: any): maybeFn is Any {
    return typeof maybeFn === "function";
  }
  is.some = (...maybeFns: any[]) => maybeFns.some(is);

  export function memo<Fn extends UFunction.Any>(fn: Fn) {
    type Args = any[];
    type Result = any;
    // TODO: Use WeakMap here, holding arg references is fine when function is
    // ephemeral, but otherwise this memory will grow for long lived functions.
    // Impelment separate memo.deep that requires invalidation
    const cache = new Map<Args, Result>();

    return Object.assign(
      ((...args) => {
        const ref = Tuple.from(args);

        if (!cache.has(ref)) cache.set(ref, fn(...args));

        return cache.get(ref);
      }) as Fn,
      {
        invalidate(...args: Parameters<Fn>) {
          return cache.delete(Tuple.from(args));
        },
      },
    );
  }

  export const unwrap = <T, Args extends any[]>(
    fn: UFunction.Maybe<Args, T>,
    ...args: Args
  ): T => (typeof fn === "function" ? (fn as UFunction.Any)(...args) : fn);

  export const noop = () => {};
  export const identity = <T,>(value: T) => value;
  export const todo = () => {
    throw new Error("Unimplemented");
  };

  export const withArgs = <
    Args extends any[],
    Fn extends (...args: Args) => any,
  >(
    fn: Fn,
    ...args: Args
  ) => [fn, args];
}

export default UFunction;
