import UFunction from "./UFunction.mts";
import UObject from "./UObject.mts";

type Chainable<
  Factory extends (options: any) => UFunction.Any,
  Modifiers,
> = ReturnType<Factory> & { [Key in keyof Factory]: Factory[Key] } & {
  [Key in keyof Modifiers]: Modifiers[Key] extends UFunction.Any
    ? (...args: Parameters<Modifiers[Key]>) => Chainable<Factory, Modifiers>
    : Chainable<Factory, Modifiers>;
};

namespace Chainable {
  // Typescript doesn't infer `options` from `T extends (options: UObject.Any)
  // => UFunction.Any` when function is passed directly in. For now we can wrap
  // it in `wrapFactory` to force the type to resolve before getting passed in.
  // TODO: Figure out how to force argument inference directly
  export const wrapFactory = <T, U extends T>(a: T): U => a as any;

  export const create = <
    Factory extends (options: UObject.Any) => UFunction.Any,
    const Modifiers extends Record<
      string,
      UFunction.Maybe<any[], Parameters<Factory>[0]>
    >,
  >(
    factory: Factory,
    modifiers: Modifiers,
    options: Parameters<Factory>[0] = {},
  ): Chainable<Factory, Modifiers> =>
    new Proxy(factory, {
      get(target, p: any) {
        const modifier = (modifiers as any)[p];
        if (!modifier) return (target as any)[p];
        if (typeof modifier === "function")
          return (...args: []) =>
            create(factory, modifiers, {
              ...options,
              ...modifier(...args),
            });
        return create(factory, modifiers, {
          ...options,
          ...modifier,
        });
      },
      apply: (target, _, args) => target(options)(...args),
    }) as any;

  /**
   * Attach option to defer state updates that change ui too early
   */
  export function deferrable<
    WithConfig extends (config: { defer: boolean }) => UFunction.Any,
  >(withConfig: WithConfig) {
    return Object.assign(withConfig({ defer: false }), {
      defer: withConfig({ defer: true }),
    }) as ReturnType<WithConfig> & { defer: ReturnType<WithConfig> };
  }
}

export default Chainable;
